export const DIMINISHED_SYMBOL = "\u00B0"
export const HALF_DIMINISHED_SYMBOL = "\u2205"
export const MAJOR_SYMBOL = "\u25B3"

export const DOUBLE_FLAT = "\u{1D12B}"
export const FLAT = "\u266D"
export const NATURAL = "\u266E"
export const SHARP = "\u266F"
export const DOUBLE_SHARP = "\u{1D12A}"

export const ROM_I = "\u2160"
export const ROM_II = "\u2161"
export const ROM_III = "\u2162"
export const ROM_IV = "\u2163"
export const ROM_V = "\u2164"
export const ROM_VI = "\u2165"
export const ROM_VII = "\u2166"

export const ROM_i = "\u2170"
export const ROM_ii = "\u2171"
export const ROM_iii = "\u2172"
export const ROM_iv = "iv" //  \u2173"  BUG - this unicode is rendering as ix, not iv
export const ROM_v = "\u2174"
export const ROM_vi = "\u2175"
export const ROM_vii = "\u2176"
