// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-account-change-password-js": () => import("./../src/pages/account/change-password.js" /* webpackChunkName: "component---src-pages-account-change-password-js" */),
  "component---src-pages-account-confirm-account-js": () => import("./../src/pages/account/confirm-account.js" /* webpackChunkName: "component---src-pages-account-confirm-account-js" */),
  "component---src-pages-account-delete-account-js": () => import("./../src/pages/account/delete-account.js" /* webpackChunkName: "component---src-pages-account-delete-account-js" */),
  "component---src-pages-account-js": () => import("./../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-account-login-js": () => import("./../src/pages/account/login.js" /* webpackChunkName: "component---src-pages-account-login-js" */),
  "component---src-pages-account-request-password-reset-js": () => import("./../src/pages/account/request-password-reset.js" /* webpackChunkName: "component---src-pages-account-request-password-reset-js" */),
  "component---src-pages-account-reset-password-js": () => import("./../src/pages/account/reset-password.js" /* webpackChunkName: "component---src-pages-account-reset-password-js" */),
  "component---src-pages-account-signup-js": () => import("./../src/pages/account/signup.js" /* webpackChunkName: "component---src-pages-account-signup-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-home-js": () => import("./../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-practice-bass-js": () => import("./../src/pages/practice/bass.js" /* webpackChunkName: "component---src-pages-practice-bass-js" */),
  "component---src-pages-practice-flute-js": () => import("./../src/pages/practice/flute.js" /* webpackChunkName: "component---src-pages-practice-flute-js" */),
  "component---src-pages-practice-guitar-js": () => import("./../src/pages/practice/guitar.js" /* webpackChunkName: "component---src-pages-practice-guitar-js" */),
  "component---src-pages-practice-steel-guitar-js": () => import("./../src/pages/practice/steel-guitar.js" /* webpackChunkName: "component---src-pages-practice-steel-guitar-js" */),
  "component---src-pages-practice-trumpet-js": () => import("./../src/pages/practice/trumpet.js" /* webpackChunkName: "component---src-pages-practice-trumpet-js" */),
  "component---src-pages-practice-voice-js": () => import("./../src/pages/practice/voice.js" /* webpackChunkName: "component---src-pages-practice-voice-js" */),
  "component---src-pages-theory-js": () => import("./../src/pages/theory.js" /* webpackChunkName: "component---src-pages-theory-js" */)
}

