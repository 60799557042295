// Unicode
import { DOUBLE_FLAT, FLAT, SHARP, DOUBLE_SHARP } from "./symbols"

// Naturals
export const An = "A"
export const Bn = "B"
export const Cn = "C"
export const Dn = "D"
export const En = "E"
export const Fn = "F"
export const Gn = "G"

// Sharps
export const Ab = An + FLAT
export const Bb = Bn + FLAT
export const Cb = Cn + FLAT
export const Db = Dn + FLAT
export const Eb = En + FLAT
export const Fb = Fn + FLAT
export const Gb = Gn + FLAT

// Flats
export const As = An + SHARP
export const Bs = Bn + SHARP
export const Cs = Cn + SHARP
export const Ds = Dn + SHARP
export const Es = En + SHARP
export const Fs = Fn + SHARP
export const Gs = Gn + SHARP

// Double sharps
export const Ass = An + DOUBLE_SHARP
export const Bss = Bn + DOUBLE_SHARP
export const Css = Cn + DOUBLE_SHARP
export const Dss = Dn + DOUBLE_SHARP
export const Ess = En + DOUBLE_SHARP
export const Fss = Fn + DOUBLE_SHARP
export const Gss = Gn + DOUBLE_SHARP

// Double flats
export const Abb = An + DOUBLE_FLAT
export const Bbb = Bn + DOUBLE_FLAT
export const Cbb = Cn + DOUBLE_FLAT
export const Dbb = Dn + DOUBLE_FLAT
export const Ebb = En + DOUBLE_FLAT
export const Fbb = Fn + DOUBLE_FLAT
export const Gbb = Gn + DOUBLE_FLAT
