/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:5b1273f4-3091-41f8-80b3-647b4f47e9a4",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_iU11iNI2T",
    "aws_user_pools_web_client_id": "3mm2qui2dar0ffkijjie7rsnrh",
    "oauth": {}
};


export default awsmobile;
